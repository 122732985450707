.home-intro {
    width: 100%;
    height: 400px;
    background: url(../../img/header_img-mobile.jpg);
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-up(sm) {
        margin-top: -77px;
    }
    @include media-breakpoint-up(md) {
        background: url(../../img/header_img.jpg);
        background-size: cover;
        height: 500px;
    }
    @include media-breakpoint-up(lg) {
        height: 80vh;
        max-height: 550px;
    }
    @include media-breakpoint-up(xl) {
        max-height: 800px;
    }
}

.home-intro__content-wrapper {
    margin: 0;
}

.home-intro__content {
    @include make-col(12);
    @include make-col-ready();
    padding-left: $padding-sm;
    padding-right: $padding-sm;
    // @include media-breakpoint-up(md) {
    //     @include make-col(6.5);
    //     @include make-col-offset(2.75);
    //     padding-left: $padding-sm/2;
    //     padding-right: $padding-sm/2;
    // }
    @include media-breakpoint-up(md) {
        @include make-col(5);
        @include make-col-offset(3.5);
        padding-left: $padding-sm/2;
        padding-right: $padding-sm/2;
    }
    .header__title {
        @extend .font--scheme;
        @extend .font--reg;
        font-size: 28px;
        margin-bottom: 15px;
        color: $color-green-dark;
        @include media-breakpoint-up(md) {
            font-size: 30px;
            margin-bottom: 17px;
        }
        @include media-breakpoint-up(xl) {
            font-size: 40px;
            margin-bottom: 25px;
        }
    }
    p {
        @extend .font--reg;
        font-size: 14px;
        line-height: 1.43;
        color: #6a7b72;
        margin-bottom: 30px;
        @include media-breakpoint-up(md) {
            font-size: 16px;
            line-height: 1.5;
        }
        @include media-breakpoint-up(xl) {
            font-size: 18px;
            line-height: 1.6;
        }
    }
    .button {
        width: fit-content;
        font-size: 14px;
        padding: 0 20px;
        @include media-breakpoint-up(sm) {
            height: 48px;
            font-size: 16px;
            padding: 0 25px;
        }
    }
}
