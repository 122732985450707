.slaicos{
    display: flex;
}

.slaicos__a{
    display: block;
    margin-right: 8px;
    svg{
        width: 24px;
        height: 24px;
    }
    &:last-child{
        margin-right: 0;
    }
    &.btn-copy{
        &:hover{
            cursor: pointer;
        }
    }
}
