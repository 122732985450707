.pagination-wrapper {
    margin-top: 30px;
}

.pagination{
    justify-content: center;
}

.page-item.active .page-link {
    background: $color-1;
    border-color: $color-1;
}

.page-link {
    @extend .font--azo;
    color: #01421f;
    padding: 8px;
    font-size: 11px;
    line-height: 1;
    @include media-breakpoint-up(sm) {
        padding: 10px;
        font-size: 13px;
    }
}

.page-link:hover {
    color: #01421f;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
}

.page-link:focus {
    box-shadow: 0 0 0 0;
    -webkit-box-shadow: 0 0 0 0;
}
