// .header-active  {
//     position: relative;
//     .navbar-wrapper  {
//         position: absolute;
//         background-color: transparent;
//         border-bottom: none;
//     }
// }

.header{
    border-bottom: solid 1px $color-grey-bg;
}

.navbar-wrapper  {
    background-color: $color-white;

}

.navbar {
    display: flex;
    justify-content: space-between;
    align-content: center;
    height: 60px;
    position: relative;
    padding: 0;
    @include media-breakpoint-up(sm) {
        height: 76px;
    }
}

.menu-collapse{
    display: none;
}

.logo{
    align-self: center;
}

.icon-logo--desktop{
    display: none;
    @include media-breakpoint-up(sm) {
        display: block;
        height: 32px;
        width: 110px;
        fill: $color-green-dark;
    }
}

.icon-logo--mobile{
    display: block;
    width: 43.8px;
    height: 28px;
    @include media-breakpoint-up(sm) {
        display: none;
    }
}

.nav{
    display: flex;
    align-items: center;
    margin-right: -10px;
    .button{
        margin-right: 10px;
        .icon{
            margin-right: 0;
        }
        span{
            display: none;
        }
    }
    @include media-breakpoint-up(sm) {
        .button{
            .icon{
                margin-right: 10px;
            }
            span{
                display: block;
            }
        }
    }
}

.icon-icon_plus{
    height: 10px;
    width: 10px;
}

.icon-icon_user{
    width: 11px;
    height: 15px;
    margin-top: -3px;
}

.hamburger{
    padding: 20px 10px 20px 10px;
    cursor: pointer;
}

.icon-icon_hamburger{
    fill: $color-grey-text-dark;
}

.btn-nav-user{
    padding: 20px 10px 20px 5px;
    display: flex;
    align-items: center;
    .user__img-wrapper{
        width: 32px;
        height: 32px;
        border-radius: 50%;
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon-icon_user{
            fill: $color-white;
        }
    }
    @include media-breakpoint-up(sm) {

        .user__img-wrapper{
            width: 36px;
            height: 36px;
        }
    }
}

.user__name{
    display: none;
    @extend .font--bold;
    margin: 0;
    margin-right: 15px;
    font-size: 14px;
    color: $color-white;
    @include media-breakpoint-up(sm) {
        display: block;
    }
}

.el-collapse.nav-main,
.el-collapse.nav-user,
.el-collapse.notification-wrapper{
    width: 100%;
    top: 60px;
    right: 0;
    &:before{
        right: 3px;
    }
    &:after{
        right: 2px;
    }
    @include media-breakpoint-up(sm) {
        width: 303px;
        top: 77px;
        right: -17px;
        &:before, &:after{
            right: 15px;
        }
        &:after{
            right: 14px;
        }
    }
}

.el-collapse.nav-user,
.el-collapse.notification-wrapper{
    &:before{
        right: 45px;
    }
    &:after{
        right: 44px;
    }
    @include media-breakpoint-up(sm) {
        right: 3px;
        &:before{
            right: 15px;
        }
        &:after{
            right: 14px;
        }
    }
}

.el-collapse.notification-wrapper{
    padding: 25px;
    padding-bottom: 10px;
    background-color: $color-white;
    max-height: 100vh;
    opacity: 1;
    pointer-events: initial;
    transform: translateY(-300%);
    transition: all 0.5s cubic-bezier(.7,0,.3,1);
}

.notification-wrapper.visible{
	transform: translateY(0%);
}

.notification__p{
    @extend .font--medium;
    font-size: 15px;
    color: $color-green-darker;
    margin-bottom: 19px;
    a{
        color: $color-1;
        &:hover, &:focus, &:active{
            color: $color-green-dark;
            text-decoration: none;
        }
    }
    @include media-breakpoint-up(sm) {
        font-size: 16px;
    }
}

.notification{
    .menu{
        border-top: 1px solid $color-grey-bg;
    }
    .menu-item{
        @extend .font--medium;
    }
}

.nav__menu-wrapper{
    padding: 10px 25px;
}

.nav__menu-wrapper--white{
    .menu-item{
        @extend .font--medium;
    }
    .button--clean{
        @extend .font--medium;
    }
}

.nav__menu-wrapper--grey{
    background-color: $color-grey-bg;
    .menu-item{
        @extend .font--reg;
        a{
            border-bottom: 1px solid #e6ecea;
        }
    }
}

.nav__inner{
	height: auto;
}

.menu{
	@extend .reset-list;
}

.menu-item{
	display: block;
    width: 100%;
	a, .button--clean{
        font-size: 13px;
		color: $color-green-darker;
        display: block;
        padding: 15px 0;
        border-bottom: 1px solid $color-grey-bg;
        span{
            color: #c5ddd5;
            margin-left: 5px;
        }
		&:hover, &:focus, &:active{
			color: $color-1;
			text-decoration: none;
			outline: 0;
		}
    }
    &:last-child{
        a, .button--clean{
            border-bottom: 0;
        }
    }
    &.active{
        a{
            color: $color-1;
        }
    }
}

.menu-item .button--clean{
    text-align: left;
    width: 100%;
}

.img-avatar {
    max-width: 100%;
    max-height: 100%;
    border-radius: 100%;
    cursor: pointer;
}

.badge--stek {
    @extend .font--bold;
    color: white;
    background: $color-green-darker;
    border: 2px solid #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 0;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -7px;
    bottom: -5px;
}

.requests__li-link .badge--stek{
    border: 2px solid $color-grey-bg;
}


