.modal-dialog {
    max-width: 531px;
    @include media-breakpoint-down(sm) {
        margin: 2.5rem 1rem;
    }
}

.modal-content{
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    border: solid 1px #f1f4f3;
}

.modalstek-header{
    padding: 40px 30px;
    p{
        margin: 0;
        &:last-child {
            margin-bottom: 0;
        }
    }
    p,
    ul {
        margin-bottom: 30px;
        font-size: 16px;
    }
    ul {
        padding-left: 30px;
        li {
            margin-bottom: 10px;
        }
    }
    h3 {
        margin-bottom: 8px;
    }
    a:not(.button){
        color: $color-1;
        text-decoration: underline;
        &:hover,&:active,&:focus {
            color: $color-1;
            text-decoration: none;
        }
    }
    @include media-breakpoint-up(sm) {
        padding: 65px 70px;
    }
}

.modalstek-body{
    padding: 60px 40px;
    a:not(.button){
        color: $color-1;
        text-decoration: underline;
        &:hover,&:active,&:focus {
            color: $color-1;
            text-decoration: none;
        }
    }
}

.modalstek-footer{
    padding: 40px 30px;
    @include media-breakpoint-up(sm) {
        padding: 50px 85px 60px 85px;
    }
}

.modal-backdrop.show{
    opacity: 0.1;
}

.modal--users-info {
    @include media-breakpoint-down(sm) {
        .modalstek-footer {
            padding: 40px 30px;
        }
        .button {
            padding: 0 20px;
        }
    }
}