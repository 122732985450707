.ap-dropdown-menu {
    box-shadow: none;
    border: 1px solid $color-grey-bg;
    border-top: 0;
    border-radius: 2px;
    margin-top: 0;
}

.ap-icon-pin {
    display: none !important;
    pointer-events: none !important;
}

.ap-suggestion{
    @extend .font--light;
    padding-left: 13px;
    height: 49px;
    line-height: 49px;
    em{
        @extend .font--reg;
    }
    @include media-breakpoint-up(lg) {
        padding-left: 23px;
        height: 55px;
        line-height: 55px;
    }
}

.ap-name{
    color: $color-green-darker;
    font-size: 15px;
}

.ap-address{
    color: $color-green-flat;
    font-size: 12px;
    margin-left: 13px;
}

.ap-suggestion-icon{
    width: 20px;
    height: auto;
    margin-right: 13px;
    vertical-align: baseline;
    svg{
        height: auto;
        opacity: 0.15;
        fill: $color-green-darker;
        transform: scale(0.9) translateY(-2px);
    }
    @include media-breakpoint-up(lg) {
        margin-right: 19px;
    }
}

.create-user .ap-suggestion-icon{
    display: none;
}

.ap-cursor{
    background-color: #e8eceb;
    .ap-suggestion-icon svg{
        fill: $color-green-darker;
        transform: scale(0.9) translateY(-2px);
    }
}

.ap-footer{
    font-size: 9px;
    line-height: 9px;
    opacity: 0.5;
    a.ap-footer-algolia{
        svg {
            width: 75px;
        }
    }
}
