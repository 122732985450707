.footer {
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: 70px;
    @include media-breakpoint-up(md) {
        padding-top: 70px;
        padding-bottom: 100px;
        margin-top: 100px;
    }

    .section__row {
        display: flex;
        flex-direction: column;
        @include media-breakpoint-up(sm) {
            flex-direction: row;
        }
    }
    .section--md {
        @include media-breakpoint-up(lg) {
            padding-left: 0;
            padding-right: 0;
        }
    }

}


.footer__logo {
    @include make-col-ready();
    @include make-col(12);
    display: flex;
    justify-content: center;
    padding-bottom: 35px;
    @include media-breakpoint-up(md) {
        @include make-col(2);
        padding-left: 21px;
        padding-right: 21px;
    }
    @include media-breakpoint-up(xl) {
        @include make-col(1.5);
    }
    a {
        align-self: flex-start;
    }
    img, svg {
        width: 100%;
        max-width: 90px;
        height: auto;
    }

}


.footer__menu {
    @include make-col-ready();
    @include make-col(12);
    display: none;
    @include media-breakpoint-up(md) {
        @include make-col(4);
        padding-left: 21px;
        padding-right: 21px;
        display: flex;
    }
    @include media-breakpoint-up(xl) {
        @include make-col(3);
    }
    ul {
        min-width: 200px;
    }
    li {
        border-top: 1px solid #e6ecea;
        &:first-child {
            border-top: 0;
            a {
                padding-top: 0;
            }
        }
    }
}

.footer__cta {
    @include make-col-ready();
    @include make-col(12);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    border-bottom: 1px solid #e6ecea;
    padding-bottom: 30px;
    @include media-breakpoint-up(md) {
        @include make-col(4);
        padding-left: 21px;
        padding-right: 21px;
        align-items: flex-start;

        border-bottom: 0px;
        padding-bottom: 0px;
    }
    @include media-breakpoint-up(xl) {
        @include make-col(3);
    }
    p {
        color: $color-grey-text;
        @extend .font--azo;
        font-size: 14px;
        line-height: 1.5;
        margin-bottom: 20px;
        text-align: center;
        @include media-breakpoint-up(md) {
            text-align: left;
        }
    }
}

.footer__slaicos {
    @include make-col-ready();
    @include make-col(12);
    padding-top: 30px;
    display: flex;
    justify-content: center;
    .icon {
        width: 40px;
        height: 40px;
        color: $color-text-dark;
    }
    a {
        align-self: flex-start;
    }

    @include media-breakpoint-up(md) {
        @include make-col(2);
        justify-content: flex-end;
        padding-left: 21px;
        padding-right: 21px;
        padding-top: 0;
    }
    @include media-breakpoint-up(xl) {
        @include make-col(1.5);
    }
}