.reset-list {
	list-style: none;
	padding: 0;
	margin: 0;
}

.width100{
	width: 100%;
}

.nomargin{
	margin: 0;
}