.section-header {
    h2 {
        @extend .font--scheme;
        font-size: 20px;
        span {
            color: $color-green-brighter;
        }
    }
}

.stek-topbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.stek{
    margin-top: 20px;
    @include media-breakpoint-up(sm) {
        // margin-bottom: 50px;
        border: 0;
    }
}

.stek__img{
    @extend .bg-grey;
    position: relative;
    padding-bottom: 100%;
    align-self: start;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -20px;
    margin-right: -20px;
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @include media-breakpoint-up(sm) {
        position: relative;
        padding-bottom: 48.5%;
        margin-left: initial;
        margin-right: initial;
    }
}

.stek__content{
    padding: 20px 0 0 0;
    @include media-breakpoint-up(sm) {
        border-radius: 2px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
        border: solid 1px #f1f4f3;
        padding: 35px 30px 35px 30px;
    }
    @include media-breakpoint-up(lg) {
        padding: 35px;
    }
}

.stek__title {
    @extend .font--medium;
    margin: 0;
    margin-bottom: 4px;
    font-size: 20px;
    color: $color-green-darker;
    text-transform: capitalize;
    padding-right: 75px;
    @include media-breakpoint-up(sm) {
        margin-bottom: 5px;
        font-size: 22px;
    }
    @include media-breakpoint-up(lg) {
        font-size: 24px;
    }
}

.stek__datelocation {
    color: $color-green-bright;
    font-size: 12px;
    margin: 0;
    span{
        @extend .font--medium;
    }
    @include media-breakpoint-up(sm) {
        font-size: 14px;
    }
}

.stek__trade-properties{
    margin-top: 5px;
}

.stek__trade-propertie{
    display: flex;
    align-items: center;
    margin: 0;
    margin-top: 20px;
    font-size: 12px;
    color: $color-text-dark;
    svg{
        margin-right: 15px;
    }
    .icon-package{
        width: 20px;
        height: 17.8px;
    }
    .icon-giveaway{
       width: 20px;
       height: 20px;
    }
    @include media-breakpoint-up(sm) {
        margin-top: 25px;
        font-size: 13px;
        .icon-package{
            width: 27px;
            height: 24px;
        }
        .icon-giveaway{
            width: 27px;
            height: 27px;
        }
    }
}

.stek__description{
    margin-top: 20px;
    color: $color-grey-text;
    font-size: 13px;
    line-height: 1.69;
    margin-bottom: 30px;
    @include media-breakpoint-up(sm) {
        margin-top: 40px;
        font-size: 15px;
        line-height: 1.6;
        margin-bottom: 40px;
    }
}

.stek__user{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $color-grey-bg;
    border-bottom: 1px solid $color-grey-bg;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 20px;
    @include media-breakpoint-up(sm) {
        padding-top: 24px;
        padding-bottom: 24px;
        margin-top: 25px;
    }
}

.stek__user-info{
    display: flex;
    align-items: center;
    .user__img-wrapper{
        margin-right: 15px;
    }
}

.stek__user-name{
    margin: 0;
    a{
        font-size: 13px;
        color: $color-green-flat;
        &:hover, &:focus, &:active{
            text-decoration: none;
            color: $color-green-flat;
        }
    }
    span{
        display: block;
        font-size: 11px;
        color: $color-green-bright;
    }
    @include media-breakpoint-up(sm) {
        font-size: 14px;
    }
}

.stek-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.stek-tags{
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    .tag{
        @extend .font--medium;
        @extend .font--azo;
        padding: 4px 8px;
        color: white;
        background: $color-green-flat;
        font-size: 13px;
        border-radius: 1px;
        margin: 5px 5px 5px 0;
    }
}


.stek-actions{
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    .stek-action{
        margin-right: 10px;
        &:last-child{
            margin-right: 0;
        }
    }
}

.stekcard .stek-actions{
    @include media-breakpoint-up(sm) {
        top: -65px;
    }
}

.stek-action{
    width: 28px;
    height: 28px;
    background-color: #f8f9f8;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1);
    position: relative;
    border: 0;
    padding: 0;
    margin: 0;
    &:before{
        content: '';
        position: absolute;
        z-index: -1;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 50%;
        width: 30px;
        height: 30px;
        transform-origin: 0 0;
        transition: all .2s ease;
        border: solid 1px $color-grey-bg;
        background-color: #f8f9f8;
        border-radius: 50%;
    }
    &:hover{
        &:before{
            transform: scale(1.3) translate(-50%, -50%);
        }
    }
    @include media-breakpoint-up(sm) {
        width: 30px;
        height: 30px;
        &:before {
            width: 32px;
            height: 32px;
        }
    }
}

.stek-action.stek--favorite {
    .icon-hart {
        width: 12px;
        height: 12px;
        fill: $color-green-flat;
    }
    &:focus{
        outline: 0;
    }
}

*[data-favorite-status="1"].stek-action.stek--favorite {
    &:before {
        background-color: $color-1;
    }
    .icon-hart {
        fill: white;
    }
}

.stek-edit{
    .icon-edit{
        width: 15px;
        height: 15px;
    }
}

.stek-delete{
    .icon-trash{
        width: 13px;
        height: 16px;
    }
}

.stek-footer{
    .link{
        display: block;
        margin-top: 10px;
    }
}

.location_error {
    margin-bottom: 25px;
    display: block;
}

.hidden-input {
    opacity: 0.0;
    display: none;
}

//fix dit vooral :D
.stek-sticker {
    z-index: 10;
    bottom: 10px;
    position: absolute;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
}

.budget-tip {
    font-size: 11px;
    padding: 11px;
    background: #fafac9;
}
