.sort{
    position: relative;
    height: auto;
    .icon-sort{
        width: 8px;
        height: 13px;
    }
    @include media-breakpoint-up(lg) {
        .icon-sort{
            display: none;
        }
    }
}

.sort-btn{
    &:hover{
        .icon-icon_arrow-down{
            fill: $color-1;
        }
    }
    @include media-breakpoint-down(md) {
        cursor: pointer;
        text-align: center;
        width: 32px;
        height: 31px;
        border-radius: 2px;
        background-color: #ffffff;
        border: 1px solid $color-grey-bg;
    }
}

.sort-title{
    display: none;
    @extend .font--scheme;
    color: $color-green-darker;
    font-size: 16px;
    margin: 0;
    .icon-icon_arrow-down{
        margin-left: 6px;
        fill: #c5ddd5;
        transform: rotate(0);
        transition-property: transform;
        transition-duration: .1s;
        transition-timing-function: ease-in;
        // &.active{
        //     fill: $color-1;
        //     transform: rotate(180deg);
        // }
    }
    
    @include media-breakpoint-up(lg) {
        display: block;
        font-size: 20px;
        .icon-icon_arrow-down{
            margin-left: 8px;
        }
    }
}

.icon-icon_arrow-down{
	width: 8.2px;
	height: 4.6px;
}

.sort-items{
    @extend .reset-list;
    li:last-child{
        .sort-item{
            border-bottom: 0;
        }
    }
}

.sort-item{
    width: 100%;
    display: block;
    padding: 15px 0;
    border-bottom: 1px solid $color-grey-bg;
    margin: 0;
    cursor: pointer;
    span{
        @extend .font--light;
    }
    input{
        display: none;
    }
    p{
        margin: 0;
        font-size: 13px;
        color: $color-green-darker;
    }
    input:checked ~ p{
        color: $color-1;
    }

    &:hover, &:focus, &:active{
        text-decoration: none;
        outline: 0;
        p{
            color: $color-1;
        }
    }
}