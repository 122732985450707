.tooltipish {
	position: relative;
	display: inline-block;
}

.tooltipish .tooltipishtext {
    @extend .font--scheme;
	visibility: hidden;
	width: 100px;
	background-color: #666;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px;
	position: absolute;
	z-index: 1;
	top: 150%;
	right: -50%;
	// margin-left: -50px;
	opacity: 0;
    transition: opacity 0.3s;
    font-size: 12px;
}

.tooltipish .tooltipishtext::after {
	content: "";
	position: absolute;
	bottom: 100%;
	right: 20px;
	// margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: transparent transparent #666 transparent;
}

.tooltipish:hover .tooltipishtext {
	visibility: visible;
	opacity: 1;
}
