.font--azo{
    font-family: "azo-sans-web",sans-serif;
}

.font--scheme{
    font-family: "scheme",sans-serif;
}

.font--light{
    font-weight: 300;
}

.font--reg {
    font-weight: 400;
}

.font--medium {
    font-weight: 500;
}

.font--bold {
    font-weight: 700;
}

.font--italic{
    font-style: italic;
}

.pagetitle {
    @extend .font--scheme;
    padding: 0;
    text-align: center;
    font-size: 25px;
    color: $color-green-darker;
    margin-bottom: 15px;
    @include media-breakpoint-up(lg) {
        font-size: 34px;
        margin-bottom: 20px;
    }
}

.link {
    color: $color-text-dark;
    text-decoration: underline;
    &:hover,&:active,&:focus {
        color: $color-text-dark;
        text-decoration: none;
    }
}

.link--sm{
    font-size: 13px;
}

.link-back{
    @extend .font--scheme;
    @extend .font--reg;
    font-size: 16px;
    color: $color-green-darker;
    svg{
        width: 5px;
        height: 8px;
        margin-right: 6px;
    }
    &.white{
        color: $color-white;
        svg{
            fill: $color-white;
        }
    }
    &:hover,&:active,&:focus {
        text-decoration: none;
    }
    @include media-breakpoint-up(sm) {
        font-size: 20px;
    }
}


a{
    &:hover, &:focus, &:active{
        color: initial;
    }
}

p{
    font-size: 13px;
    line-height: 1.46;
    margin-bottom: 25px;
    @include media-breakpoint-up(sm) {
        font-size: 16px;
        line-height: 1.5;
    }
}

.p-sm{
    font-size: 13px;
    line-height: 1.69;
    margin-bottom: 10px;
    @include media-breakpoint-up(sm) {
        font-size: 15px;
        line-height: 1.6;
        margin-bottom: 25px;
    }
}


h1,h2{
    @extend .font--medium;
    font-size: 20px;
    color: $color-green-darker;
    margin: 0;
    @include media-breakpoint-up(sm) {
        font-size: 24px;
    }
}

h3{
    @extend .link-back;
    margin: 0;
}

h4{
    @extend .font--bold;
    font-size: 14px;
    color: $color-green-darker;
    margin: 0;
    margin-bottom: 15px;
    @include media-breakpoint-up(sm) {
        font-size: 16px;
    }
}

h5{
    @extend .font--medium;
    font-size: 14px;
    color: $color-green-darker;
    margin: 0;
}

.text-centered {
    text-align: center;
}