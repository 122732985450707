.collapsegroup{
    height: 100%;
    align-self: center;
    display: flex;
    align-items: center;
}

.collapsegroup--nav-user{
    @include media-breakpoint-up(sm) {
        position: relative;
    }
}

.btn-collapse{
    cursor: pointer;
}

.el-collapse{
    max-height: 0;
    height: auto;
    display: block;
    opacity: 0;
    position: absolute;

    bottom: auto;
	transition-property: max-height height;
	transition-duration: .1s;
    transition-timing-function: ease-in;
    border: solid 1px #f1f4f3;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    z-index: 99;
    background-color: $color-white;
    overflow: visible;
    pointer-events: none;
    &:before, &:after{
        z-index: -1;
        content: '';
        top: -7px;
        right: 15px;
        position: absolute;
        width: 15px;
        height: 15px;
        transform: rotate(45deg);
        border-radius: 5px;
        background-color: $color-white;
    }
    &:before{
        top: -7px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    }
    &:after{
        top: -7px;
        height: 22px;
        width: 20px;
        right: 14px;
    }
    &.toggle{
        max-height: 100vh;
        opacity: 1;
    	pointer-events: initial;
	}
}

.el-collapse.sort-wrapper{
    width: 223px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
    padding: 5px 25px;
    top: 45px;
    right: 0;
    text-align: left;
    &:before{
        right: 7px;
    }
    &:after{
        right: 6px;
    }
    &.toggle ~ .sort-title .icon-icon_arrow-down{
        fill: $color-1;
        transform: rotate(180deg);
    }
}