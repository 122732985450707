.img--objectfit{
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
    font-family: 'object-fit: cover;';
}

.img--objectfit--contain{
	object-fit: contain;
}

.icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	stroke-width: 0;
	stroke: currentColor;
	fill: currentColor;
}

#icon-cross{
	fill: $color-green-darker;
}