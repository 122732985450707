.cookie {
    position: fixed;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(md) {
        width: fit-content;
    }
}

.cookie__inner {
    display: flex;
    align-items: center;
    padding: 13px 30px;
    border-radius: 31px;
    color: white;
    background: $color-1;
    margin: 0px 25px;
    p {
        font-weight: 500;
        margin: 0 10px 0 0;
    }
    @include media-breakpoint-up(md) {
        p {
            margin: 0 50px 0 0;
        }
    }
}

.cookie.cookie--visible {
    display: flex;
}
