.searchbar{
    position: relative;
    margin-top: 15px;
    @include media-breakpoint-up(lg) {
        margin-top: 25px;
    }
}

.searchbar.header-active {
    margin-top: -25px;
    @include media-breakpoint-up(lg) {
        margin-top: -36.5px;
    }
}

.searchbar-placeholder{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 15px;
    @extend .font--azo;
    @extend .font--light;
    font-size: 12px;
    color: $color-green-flat;
    background-color: $color-white;
    border-radius: 2px;
    border: solid 1px $color-grey-bg;
    box-shadow: 0 2px 7px 0 rgba(210, 210, 210, 0.25);
    span.placeholder-filled{
        color: $color-green-darker;
    }
    @include media-breakpoint-up(sm) {
        font-size: 16px;
    }
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.searchbar__main{
    position: relative;
    display: flex;
    align-content: center;
    height: 50px;
    border-radius: 2px;
    @include media-breakpoint-up(lg) {
        height: 73px;
    }
}

.searchbar__main-content{
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-color: $color-white;
    opacity: 0;
    visibility: hidden;
    z-index: 5;
    border-radius: 2px;
    box-shadow: 0 2px 7px 0 rgba(210, 210, 210, 0.25);
    border: solid 1px $color-grey-bg;
    padding: 74px 20px 30px 20px;
    @include media-breakpoint-up(lg) {
        position: relative;
        height: 100%;
        width: 100%;
        padding: 0 30px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        opacity: 1;
        visibility: visible;
    }
}

.searchbar--active{
    z-index: 100000;
    .searchbar__main-content{
        opacity: 1;
        visibility: visible;
    }
}

.searchbar__mobileheader{
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    height: 59px;
    width: 100%;
    padding-left: 19px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    background-color: #f1f4f3;
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.searchbar__distance-filters{
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(lg) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-left: 25px;
        border-left: 1px solid $color-grey-bg;
        margin-right: 105px;
        width: 480px;
        flex: 0 0 auto;
    }
}

.searchbar__location,
.searchbar__distance{
    display: flex;
    align-items: center;
    margin: 0;
    margin-top: 23px;
    flex-wrap: wrap;
    @include media-breakpoint-up(lg) {
        margin-top: 0;
        flex-wrap: nowrap;
    }
}

.searchbar__location {
    @include media-breakpoint-up(lg) {
        width: 100%;
        margin-right: 30px;
        position: relative;
    }
}

.searchbar__label-text{
    @extend .font--bold;
    font-size: 9px;
    letter-spacing: 0.9px;
    color: #6c8778;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 5px;
    @include media-breakpoint-up(lg) {
        font-family: 'scheme', sans-serif;
        font-weight: 400;
        color: $color-green-darker;
        font-size: 16px;
        margin: 0;
        letter-spacing: normal;
        text-transform: initial;
    }
}

.search-input{
    @extend .font--azo;
    @extend .font--light;
    background-color: transparent;
    font-size: 16px;
    color: $color-green-darker;
    height: 50px;
    width: 100%;
    border: 1px solid rgb(241,244,243);
    border-radius: 2px;
    padding: 0 16px;
    margin-top: 15px;
    &:focus{
        background-color: transparent;
        outline: 0;
        border: 1px solid rgb(241,244,243);
        box-shadow: none;
    }
    @include media-breakpoint-up(lg) {
        font-size: 18px;
        color: $color-green-flat;
        height: 36px;
        padding-left: 0;
        margin: 0;
        border: 1px solid white;
        &:focus{
            border: 1px solid white;
        }
    }
}

.search-input::placeholder{
    @extend .font--light;
    color: $color-green-flat;
}

.select-distance-wrapper{
	border-radius: 2px;
	border: 1px solid $color-grey-bg;
    width: 100%;
    height: 50px;
    display: flex;
    background-color: #f8f9f8;
    vertical-align: middle;
    padding-right: 15px;
    @include media-breakpoint-up(lg) {
        width: 110px;
        height: 38px;
    }
}

.searchbar__distance .searchbar__label-text{
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.select-distance{
	@extend .font--azo;
	@extend .font--reg;
	background-color: transparent;
	border: 0;
    font-size: 15px;
    padding: 0 15px;
    width: 100%;
    color: $color-green-flat;
    &:hover,
    &:focus,
    &:active {
        outline: 0;
    }
    @include media-breakpoint-up(lg) {
        font-size: 14px;
        width: auto;
    }
}

.button--submit-search{
    position: absolute;
    left: 20px;
    bottom: 30px;
    letter-spacing: 2px;
    width: calc(100% - 40px);
    @include media-breakpoint-up(lg) {
        left: initial;
        bottom: initial;
        font-size: 12px;
        letter-spacing: 1px;
        height: 36px;
        padding: 0 13px;
        width: auto;
        right: 30px;
    }
}