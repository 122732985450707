.post-index{
    .post-item__featured-img{
        max-width: 100%;
        height: 185px;
    }
    .post-item__header{
        margin-top: 15px;
    }
    h2{
        margin-bottom: 10px;
    }
    @include media-breakpoint-up(sm) {
        .post-item__featured-img{
            height: 300px;
        }
    }
    @include media-breakpoint-up(lg) {
        .post-item__featured-img{
            height: 340px;
        }
        .post-item__header{
            margin-top: 25px;
        }
        h2{
            margin-bottom: 15px;
        }
    }
    @include media-breakpoint-up(xl) {
        .post-item__featured-img{
            height: 379px;
        }
    }
}

.post-items{
    margin-top: 25px;
    @include media-breakpoint-up(sm) {
        margin-top: 45px;
    }
    @include media-breakpoint-up(xl) {
        margin-top: 75px;
    }
}

.post-item{
    margin-bottom: 35px;
    @include media-breakpoint-up(sm) {
        margin-bottom: 60px;
    }
    @include media-breakpoint-up(lg) {
        margin-bottom: 70px;
    }
    @include media-breakpoint-up(xl) {
        margin-bottom: 100px;
    }
}

.post-item__featured-img-wrapper{
    display: block;
    &:hover ~ .post-item__header .post-item__title a,
    &:focus ~ .post-item__header .post-item__title a{
        color: $color-1;
    }
}

.post-item__date{
    font-size: 12px;
    margin-bottom: 5px;
    color: $color-green-bright;
    @include media-breakpoint-up(sm) {
        font-size: 13px;
        margin-bottom: 10px;
    }
    @include media-breakpoint-up(lg) {
        font-size: 14px;
    }
}

.post-item__title{
    a{
        color: inherit;
        &:hover, &:focus{
            color: $color-1;
            text-decoration: none;
        }
    }
}

.post-item__excerpt{
    margin-bottom: 20px;
    color: $color-grey-text;
}

.post-item__user-info{
    display: flex;
    align-items: center;
    position: relative;
    .user__img-wrapper{
        margin-right: 8px;
        position: relative;
        img {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

.post-single{
    margin-top: 25px;
    @include media-breakpoint-up(lg) {
        margin-top: 50px;
    }
}

.post-topbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.post{
    margin-top: 20px;
    h1{
        margin-bottom: 10px;
    }
    @include media-breakpoint-up(sm) {
        h1{
            margin-bottom: 25px;
        }
    }
}

.post-item__header{
    margin-top: 15px;
    @include media-breakpoint-up(sm) {
        margin-top: 25px;
    }
    @include media-breakpoint-up(lg) {
        margin-top: 50px;
    }
}

.post-item__intro{
    font-size: 16px;
    line-height: 1.55;
    margin-bottom: 25px;
    @include media-breakpoint-up(sm) {
        font-size: 17px;
        line-height: 1.65;
        margin-bottom: 50px;
    }
}