.button {
    @extend .font--bold;
    text-decoration: none;
    font-size: 16px;
    letter-spacing: 1.31px;
    text-transform: uppercase;
    padding: 0 40px;
    border: 0;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    transition: all .2s ease;
    line-height: 1.2;
    .icon {
        margin-right: 10px;
    }
    &:hover,
    &:focus,
    &:active{
        text-decoration: none;
        outline: 0;
        .icon svg {
            fill: inherit;
        }
    }
    @include media-breakpoint-up(sm) {
        height: 56px;
        letter-spacing: 1.5px;
    }
}

.button--sm {
    height: 32px;
    padding: 0 11px;
    font-size: 12px;
    letter-spacing: 1px;
    @include media-breakpoint-up(sm) {
        height: 36px;
        padding: 0 13px;
    }
}

.button--white {
    background-color: $color-white;
    color: $color-green-darker;
    &:hover, &:focus, &:active{
        background-color: $color-grey-bg;
        color: $color-green-darker;
    }
}

.button--white-red {
    background-color: $color-white;
    color: $color-red;
    &:hover, &:focus, &:active{
        color: $color-red;
    }
}

.button--green {
    background-color: $color-1;
    color: $color-white;
    .icon{
        fill: $color-white;
    }
    &.button--disabled {
        background-color: #c5ddd5;
        &:hover {
            background-color: #c5ddd5;
        }
    }
    &:hover,
    &:focus,
    &:active {
        background-color: #2bbc6d;
        color: $color-white;
    }
}

.button--grey {
    background-color: #efefef;
    color: #333;
    cursor: pointer;
    &:hover,
    &:focus,
    &:active {
        background-color: #ccc;
        color: #fff;
    }
}

.button--green-dark {
    background-color: $color-green-dark;
    color: $color-white;
    &:hover,
    &:focus,
    &:active {
        background-color: #11533f;
        color: $color-white;
    }
}

.button--red {
    background-color: $color-red;
    color: $color-white;
    &:hover,
    &:focus,
    &:active {
        background-color: #c11d1d;
        color: $color-white;
    }
}

.button--clean {
    background-color: transparent;
    border: 0;
    &:hover,
    &:focus,
    &:active {
        outline: 0;
    }
}

.button--center {
    margin-left: auto;
    margin-right: auto;
}

.button--round {
    width: 31px;
    height: 31px;
    border-radius: 50%;
    border: 0;
    background-color: $color-white;
}

.button--fblogin {
    @extend .button--inline-flex;
    background-color: #1f5493;
    color: white;
    width: auto;
    padding: 0 20px;
    font-size: 13px;
    letter-spacing: 1px;
    .icon{
        margin-right: 13px;
    }
    svg{
        width: 8.9px;
        height: 16.5px;
    }
    span{
        padding-left: 13px;
        position: relative;
        &:before{
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            height: 24px;
            border-radius: 2px;
            background-color: #346096;
        }
    }
    &:hover,
    &:focus,
    &:active {
        background-color: #14447b;
        color: $color-white;
    }
    @include media-breakpoint-up(sm) {
        padding: 0 25px;
        font-size: 16px;
        letter-spacing: 1.5px;
        .icon {
            margin-right: 16px;
        }
        svg {
            width: 11px;
            height: 21px;
        }
        span {
            padding-left: 16px;
            &:before {
                height: 30px;
            }
        }
    }
}

.button-comment {
    padding: 2px 20px;
    border-left: 1px solid $color-grey-bg;
    &:focus {
        outline: 0;
    }
}

.button--inline-flex {
    display: inline-flex;
}

.submitted .button {
    transition: all .1s ease;
    opacity: 0.5;
}

.button-mobile {
    width: 32px;
    height: 31px;
    border: 1px solid #f1f4f3;
    border-radius: 2px;
    background-color: #ffffff;
    &:focus {
        outline: none;
    }
    @include media-breakpoint-up(sm) {
        display: none;
    }
}

.button--rounded {
    font-size: 12px;
    letter-spacing: 1px;
    border-radius: 18px;
    height: auto;
    padding: 10px 15px;
}
