.cardcontainer {
    @include make-col-ready();
    @include make-col(12);
    flex-wrap: wrap;
    padding: 0;
    @include media-breakpoint-up(sm) {
        flex-direction: row;
        padding-left: $padding-sm/2;
        padding-right: $padding-sm/2;
    }
    @include media-breakpoint-up(xl) {
        padding-left: $padding-xl/2;
        padding-right: $padding-xl/2;
    }
}

.cardcontainer.loading {
    opacity: 0.6;
    transition: all 0.8s ease;
}

.cardcontainer__row {
    margin-left: 0;
    margin-right: 0;
    @include media-breakpoint-up(sm) {
        margin-left: -$padding-sm/2;
        margin-right: -$padding-sm/2;
    }
    @include media-breakpoint-up(xl) {
        margin-left: -$padding-xl/2;
        margin-right: -$padding-xl/2;
    }
}

.cardcontainer-title {
    @extend .font--scheme;
    font-size: 16px;
    color: $color-green-darker;
    span {
        color: $color-green-brighter;
    }
    @include media-breakpoint-up(sm) {
        font-size: 20px;
    }
}

.cardcontainer-actions {
    display: flex;
}

.stekcard {
    @include make-col-ready();
    @include make-col(12);
    padding: 0;
    position: relative;
}

.stekcard:not(.stekcard--asmobile) {
    margin-bottom: 7px;
    @include media-breakpoint-up(sm) {
        @include make-col(6);
        padding-left: $padding-sm/2;
        padding-right: $padding-sm/2;
        margin-bottom: 51px;
    }
    @include media-breakpoint-up(md) {
        @include make-col(4);
    }
    @include media-breakpoint-up(lg) {
        @include make-col(3);
    }
    @include media-breakpoint-up(xl) {
        padding-left: $padding-xl/2;
        padding-right: $padding-xl/2;
    }
}

.stekcard--asmobile .stekcard {
    margin-top: 20px;
    @include media-breakpoint-up(lg) {
        margin-top: 40px;
    }
}

.stekcard--own {
    .stekcard__datelocation {
        margin-right: 35px;
    }
    .stekcard__title a {
        margin-right: 35px;
    }
}

.stekcard--own.stekcard--profile {
    .stekcard__icons {
        display: none;
    }
    @include media-breakpoint-up(lg) {
        .stekcard__icons {
            display: block;
        }
    }
}


.stekcard__inner {
    width: 100%;
    display: flex;
    overflow: hidden;
    border-radius: 2px;
    background-color: $color-white;
    border-top: solid 1px #f1f4f3;
    border-bottom: solid 1px #f1f4f3;
    @include media-breakpoint-up(sm) {
        flex-direction: column;
        height: 100%;
        border: solid 1px #f1f4f3;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    }
}

.stekcard--asmobile .stekcard__inner {
    border: solid 1px #f1f4f3;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    @include media-breakpoint-up(sm) {
        flex-direction: row;
    }
}


.stekcard__img-wrapper {
    position: relative;
}

.stekcard__img {
    @extend .bg-grey;
    width: 110px;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    img,
    .stekcard__img--placeholder {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @include media-breakpoint-up(sm) {
        width: 100%;
        padding-bottom: 100%;
        height: auto;
    }
}

.stekcard--profile .stekcard__img {
    width: 78px;
    @include media-breakpoint-up(sm) {
        width: 100%;
    }
}

.stekcard--asmobile .stekcard__img {
    @include media-breakpoint-up(sm) {
        height: 100%;
        width: 120px;
    }
}

.stekcard__img--placeholder {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-logo_placeholder {
    width: 43px;
    height: 66.3px;
    @include media-breakpoint-up(sm) {
        width: 112px;
        height: 172.7px;
    }
}


.stek--reserved {
    position: absolute;
    bottom: 20px;
    left: 15px;
    z-index: 110;
    background-color: #aab7b0;
    color: white;
    padding: 3px 10px;
    font-size: 10px;
    border-radius: 2px;
}


.stekcard__content {
    display: flex;
    flex-direction: column;
    padding: 17px 20px 15px 15px;
    width: 100%;
    position: relative;
    @include media-breakpoint-up(sm) {
        padding: 15px 20px;
        flex-grow: 1;
        min-height: 70px;
    }
}

.stekcard__header {
    flex-grow: 1;
    position: relative;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    padding-right: 30px;
    @include media-breakpoint-up(sm) {
        padding-right: 0;
    }
}

.stekcard__datelocation {
    color: $color-green-bright;
    font-size: 11px;
    line-height: 14px;
    margin: 0;
    margin-bottom: 11px;
    span {
        @extend .font--medium;
    }
}

.stekcard--profile .stekcard__datelocation {
    margin-bottom: 5px;
}

.stekcard__title a {
    @extend .font--medium;
    margin: 0;
    margin-bottom: 2px;
    color: $color-green-darker;
    font-size: 15px;
    line-height: 19px;
    // Max 1 lines
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    max-height: 19px * 1;
    -webkit-line-clamp: 1;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }
    @include media-breakpoint-up(sm) {
        font-size: 16px;
        line-height: 20px;
        // Max 2 lines
        max-height: 20px * 2;
        -webkit-line-clamp: 2;
    }
}

.cardcontainer .stekcard {
    .stek-action.stek--favorite{
        &:before{
            display: none;
            border: 0;
        }
    }

    *[data-favorite-status="1"].stek--favorite {
        .icon-hart {
            fill: $color-1;
        }
    }
    @include media-breakpoint-up(sm) {
        .stek-action.stek--favorite {
            &:before{
                display: block;
            }
        }
        &:hover .stek--favorite{
            opacity: 1;
        }
        *[data-favorite-status="1"].stek--favorite {
            .icon-hart {
                fill: #fff;
            }
        }
    }
}

.stekcard--asmobile .stek--favorite{
    display: none;
}

.stekcard__footer{
    position: relative;
}

.stekcard__user-info {
    display: flex;
    align-items: center;
    border-top: 1px solid $color-grey-bg;
    padding-top: 10px;
    position: relative;
    .user__img-wrapper {
        margin-right: 8px;
        position: relative;
        img {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    @include media-breakpoint-up(sm) {
        padding-top: 13px;
    }
}

.stekcard--profile .stekcard__footer{
    display: none;
}

.stekcard__icons {
    position: absolute;
    right: 0;
    bottom: 0;
    svg {
        margin-right: 8px;
        &:last-child {
            margin-right: 0;
        }
    }
}

.stekcard__user-name {
    font-size: 11px;
    color: $color-green-flat;
    margin: 0;
    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
        color: $color-green-flat;
    }
}