.image-upload {
	background: $color-white;
	border-radius: 2px;
	padding: 30px 30px;
	@include media-breakpoint-up(sm) {
		padding: 45px 60px;
	}
	input[type="file"] {
		width: 0;
		height: 0;
		overflow: hidden;
	}
}

.image-upload__images {
	display: flex;
}

.image-upload__image {
	flex: 1 0 100%;
	position: relative;
	background: $color-grey-bg;
	border-radius: 2px;
	&:after {
		content: '';
  		display: block;
  		padding-bottom: 100%;
	}
}

.image-upload__placeholder-image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	svg {
		width: 100%;
		height: auto;
	}
}

.image-upload__preview {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.image-upload__preview-image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: transparent;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
}

.image-upload--uploaded {
	//background: green;
}

.image-upload--error {
	border: 1px solid $color-red;
	.image-upload__instructions {
		color: $color-red;
		opacity: 1;
	}
}

.image-upload--uploading {
	//background: blue;
}


.image-upload__progress {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	.image-upload--uploading & {
		opacity: 1;
	}
}


@keyframes progressing {
    0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(100%);
	}
}

.progress__bar {
	background: $color-white;
	border-radius: 6px;
	width: 100px;
	height: 13px;
	position: relative;
	overflow: hidden;
	&:after {
		content: '';
		width: 100%;
		height: 100%;
		background: $color-1;
		top: 0;
		left: 0;
		display: block;
		transform: translateX(-50%);
		border-radius: 6px;

		animation-name: progressing;
		animation-duration: 1s;
		animation-iteration-count: infinite;
	}
}



.image-upload__actions {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
}

.image-upload__instructions {
	@extend .font--azo;
	@extend .font--light;
	font-size: 11px;
	line-height: 14px;
	opacity: 0.35;
	margin-top: 15px;
	-webkit-appearance: none !important;
}

.actions__placeholder {
	display: flex;
	flex-direction: column;
	transition: opacity .3s ease-in-out 0s;
	.image-upload--uploading & {
		opacity: 0.5;
	}
	.image-upload--uploaded & {
		opacity: 0;
		transition: opacity 0s ease-in-out 0s;
		position: none;
		user-select: none;
	}

}

.actions__preview {
	opacity: 0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	flex-direction: column;
	align-items: center;
	.image-upload--uploaded & {
		opacity: 100;
		transition: opacity 0s ease-in-out 0s;
		display: flex;
		align-items: center;
	}
}

.image-upload__remove-file {
	margin-top: 5px;
	cursor: pointer;
	text-decoration: underline;
	&:hover,&:active,&:focus {
		text-decoration: none;
	}
}