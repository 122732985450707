.requests__ul-wrapper{
    position: relative;
    @include media-breakpoint-up(sm) {
        &:after{
            content: '';
            background-image: linear-gradient(to bottom, rgba(255,255,255,0), #fff 100%);
            position: absolute;
            bottom: 0;
            left: 1%;
            height: 60px;
            width: 98%;
            pointer-events: none;
        }
    }
}

.requests__ul{
    @extend .reset-list;
    border-radius: 2px;
    border: solid 1px #f1f4f3;
    margin-bottom: 20px;
    @include media-breakpoint-up(sm) {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
        max-height: 350px;
        overflow: scroll;
        padding-bottom: 30px;
    }
}

.requestsingle .section-header,
.requestindex .section-header{

    @include media-breakpoint-down(sm) {
        padding-left: 20px;
        padding-right: 20px;
        height: 40px;
    }
}

.requestsingle{
    .requests__li-text-wrapper{
        width: 100%;
        min-width: auto;
    }
    .requests__li-content .status-label{
        display: none;
    }

    @include media-breakpoint-down(sm) {
        .section-half-column1{
            display: none;
        }
    }
}

.requests__li.active{
    .requests__li-link{
        background-color: $color-grey-bg;
        border-left: 3px solid $color-1;
    }
}

.requests__li-link{
    background-color: $color-white;
    display: block;
    height: auto;
    &:hover, &:focus{
        background-color: #F0F3F0;
        text-decoration: none;
    }
    @include media-breakpoint-up(sm) {
        height: 62px;
    }

    .badge--stek {
        display: none;
    }
}

.requests__li-link.unread{
    .badge--stek {
        display: flex;
    }
}

.requests__li-link.unread.nocomments {
    .badge--stek {
        display: none;
    }
}

.requests__li-inner{
    height: 100%;
    padding: 15px 20px;
    position: relative;
    border-bottom: 1px solid $color-grey-bg;
    @include media-breakpoint-up(sm) {
        border-bottom: none;
        padding: 0 19px;
    }
}

.requests__li-content{
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    @include media-breakpoint-up(sm) {
        border-bottom: 1px solid $color-grey-bg;
    }
}

.requests__li-text-wrapper{
    display: flex;
    flex-wrap: wrap;
    margin-left: 15px;
    color: $color-green-darker;
    width: 100%;
    .icon-trade{
        margin-left: 8px;
        margin-right: 8px;
    }
    @include media-breakpoint-up(sm) {
        width: 70%;
    }
    @include media-breakpoint-up(lg) {
        min-width: 500px;
        width: 83%;
    }
}

.requests__li-text{
    @extend .font--light;
    font-size: 12px;
    margin: 0;
    padding-top: 2px;
    line-height: 14px;
    max-height: 16px*1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    position: relative;
    padding-right: 120px;
}

.requests__li-time{
    @extend .font--medium;
    color: #c5ddd5;
    margin: 0;
    font-size: 12px;
    position: absolute;
    right: 0;
    bottom: 0;
}

.requestcard{
    border-radius: 2px;
    border: solid 1px #f1f4f3;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 #0000000c;
}

.requestcard__header{
    padding: 15px 20px;
}

.requestcard__topbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.requestcard__user{
    display: flex;
    justify-content: flex-start;
    height: 100%;
    align-items: center;
    h5{
        margin-left: 15px;
        a{
            color: $color-green-darker;
        }
    }
}

.status-label{
    @extend .font--bold;
    font-size: 9px;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    border-radius: 14px;
    padding: 5px 7px;
    margin-top: 7px;
    @include media-breakpoint-up(sm) {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        margin-top: 0;
    }
}

.status-label--red{
    color: $color-red;
    background-color: #F5E0E0;
}

.status-label--green{
    color: $color-1;
    background-color: #E2F7EC;
}

.status-label--blue{
    background: #d6e7f9;
    color: #333;
}

.requestcard__comments{
    background-color: #f8f9f8;
    height: 407px;
    padding: 20px 30px;
    border-top: 1px solid $color-grey-bg;
    // border-bottom: 1px solid $color-grey-bg;
    overflow-y: auto;
}

.requestcard__comments-ul{
    @extend .reset-list;
}

.requestcard__comments-li{
    margin-bottom: 15px;
}

.requestcomment{
    @extend .font--light;
    background-color: $color-white;
    padding: 10px 35px 25px 15px;
    color: #242424;
    max-width: 85%;
    min-width: 100px;
    display: inline-block;
    z-index: 2;
    border-radius: 6px;
    box-shadow: 0 2px 4px 0 #00000017;
    p{
        margin: 0;
    }
}

.requestcard__comments-li.user-comment{
    .requestcomment{
        position: relative;
        background-color: $color-green-dark;
        color: $color-white;
        &:before, &:after{
            z-index: -1;
            content: '';
            position: absolute;
            border-radius: 1px;
            background-color: $color-green-dark;
            z-index: -7;
            top: 13px;
            left: -5px;
            width: 10px;
            height: 10px;
            transform: rotate(45deg);
        }
        &:before{
            box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.09);
        }
        &:after{
            height: 11px;
            width: 12px;
        }
    }
    .requestcomment__text{
        @extend .font--reg;
        color: $color-white;
    }
}

.requestcard__comments-li.user-comment.own-comment{
    text-align: right;
    .requestcomment{
        background-color: $color-1;
        &:before, &:after{
            background-color: $color-1;
            left: auto;
            right: -5px;
            transform: rotate(-45deg);
        }
    }
}

.requestcard__comments-li.system-comment{
    .requestcomment{
        border-radius: 3px;
        padding: 10px 15px;
        max-width: 100%;
    }
}

.requestcomment__text{
    @extend .font--light;
    color: $color-text-dark;
    font-size: 13px;
    line-height: 1.38;
    margin: 0;
    span{
        @extend .font--medium;
    }
    a{
        @extend .font--medium;
        color: $color-1;
        text-decoration: underline;
        &:hover, &:focus, &:active{
            text-decoration: none;
        }
    }
}

.requestcomment__time{
    @extend .font--reg;
    position: absolute;
    bottom: 6px;
    right: 8px;
    font-size: 9px;
}

.requestcard__new-comment{
    background-color: $color-white;
    border-top: 1px solid $color-grey-bg;
    border-bottom: 1px solid $color-grey-bg;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.input-comment{
    @extend .font--light;
    line-height: 1.38;
    color: #242424;
    font-size: 13px;
    border: 0;
    width: 100%;
    padding: 0;
    padding: 18px 20px;
    // resize: none;
    &:focus{
        outline: 0;
    }
}

.input-comment::-webkit-input-placeholder{
    color: $color-green-flat;
    font-size: 14px;
}

.trashed-text{
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    p{
        @extend .font--light;
        font-size: 12px;
        line-height: 1.5;
        color: #242424;
        margin: 0;
    }
}
