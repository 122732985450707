// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: "azo-sans-web", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$color-text-dark: #3C3C3C;
$color-white: #fff;

$color-1: #3cc87c;
$color-green-dark: #186f55;
$color-green-darker: #01421f;
$color-green-brighter: #c5ddd5;
$color-green-bright: #7bbf9a;
$color-green-flat: #aab7b0;
$color-grey-bg: #f1f4f3;
$color-grey-text: #898989;
$color-grey-text-dark: #3c3c3c;
$color-red: #b92d2d;

//layout
$padding-xs: 20px;
$padding-sm: 30px;
$padding-xl: 42px;