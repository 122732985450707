#filter {
    legend {
        color: #6c8778;
        @extend .font--azo;
        @extend .font--medium;
        font-size: 10px;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid transparent;
        padding-bottom: 15px;
        padding-top: 15px;
        cursor: pointer;
        .icon-icon_arrow-down{
            fill: $color-green-darker;
            transform: rotate(180deg);
            transition-property: transform;
            transition-duration: .1s;
            transition-timing-function: ease-in;
        }
        &.collapsed{
            border-bottom: 1px solid #f1f4f3;
            .icon-icon_arrow-down{
                fill: $color-green-brighter;
                transform: rotate(0);
            }
            &:hover{
                .icon-icon_arrow-down{
                    fill: $color-green-darker;
                }
            }
        }
    }
    fieldset {
        .form-check--filter:last-child,
        .form-checkbutton:last-child {
            padding-bottom: 35px;
        }
        .form-check--filter:last-child {
            border-bottom: 1px solid transparent;
        }

    }
    @include media-breakpoint-down(md) {
        padding: 10px 20px 30px 20px;
    }

    @include media-breakpoint-up(md) {
        padding-right: 25px;
    }
}

#filter .filtersets fieldset:last-child legend.collapsed{
    border-bottom: 1px solid transparent;
}

.section__filter {
    .filter-button {
        display: none;
    }
    .filter-header {
        display: none;
        height: 59px;
        padding-left: 19px;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        background-color: $color-grey-bg;
    }
    @include media-breakpoint-down(md) {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        height: 100vh;
        overflow: auto;
        width: 100%;
        transform: translateX(-100%);
        transition: transform 0.3s ease 0s, visibility 0.1s linear 0.3s;
        background: #ffffff;
        visibility: hidden;
        z-index: 5;
        .section-header{
            display: none;
        }
        .filter-header{
            display: flex;
            align-items: center;
        }
        .filter-button{
            text-align: center;
            display: block;
            position: relative;
            height: auto;
            padding: 14px 0;
            margin-top: 30px;
            cursor: pointer;
        }
    }
}

.mobile-filter {
    cursor: pointer;
    text-align: center;
    width: 32px;
    height: 31px;
    border-radius: 2px;
    background-color: #ffffff;
    border: 1px solid $color-grey-bg;
    margin-right: 8px;
    .icon-mobile-filter{
        width: 14px;
        height: 10px;
    }
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.section__filter--show {
    transform: translate(0);
    visibility: visible;
    transition: transform 0.3s ease 0s, visibility 0s linear 0s;
}
