.form-section{
    padding-bottom: 40px;
    @include media-breakpoint-up(sm) {
        padding-top: 54px;
        padding-bottom: 89px;
    }
}

.form-section__header{
    @extend .bg-white;
    text-align: center;
    padding: 50px 20px;
    .stekcard{
        text-align: left;
    }
    p:not(.stekcard__datelocation){
        margin: 0;
    }
    .button--fblogin, .button-modal-stek-delete {
        margin-top: 25px;
    }
    @include media-breakpoint-up(sm) {
        padding: 65px 30px;
        .button--fblogin {
            margin-top: 40px;
        }
        .button-modal-stek-delete{
            margin-top: 30px;
        }
    }
    @include media-breakpoint-up(lg) {
        padding: 65px 52px;
    }
}

.form-section__body{
    padding: 50px 30px;
    // @include media-breakpoint-up(md) {
    //     padding: 50px 30px;
    // }
    @include media-breakpoint-up(lg) {
        padding: 50px 50px 60px 50px;
    }
}

.form-section__link-wrapper{
    margin-top: 25px;
    @include media-breakpoint-up(lg) {
        margin-top: 30px;
    }
}


.filter-item{
    @extend .font--medium;
    display: flex;
    align-items: center;
    border-radius: 2px;
    background-color: $color-green-flat;
    color: $color-white;
    font-size: 11px;
    height: 26px;
    padding: 0 7px;
    margin: 0;
    margin-right: 10px;
    transition-property: background-color;
    transition-duration: .1s;
    transition-timing-function: ease-in;
    .checkbox{
        display: none;
    }
    &:last-child{
        margin-right: 0;
    }
    &:hover,
    &.active{
        background-color: $color-1;
        cursor: pointer;
    }
    &.active {
        &::after {
            content:" \d7";
            margin-left: 4px;
        }
    }
}

.form-group{
    margin-bottom: 23px;
    @include media-breakpoint-up(lg) {
        margin-bottom: 25px;
    }
}

.form-section__body form{
    button[type=submit]{
        margin-top: 35px;
        @include media-breakpoint-up(lg) {
            margin-top: 40px;
        }
    }
}

label {
    cursor: pointer;
}

label.label--formfield,
legend.label--formfield{
    @extend .font--bold;
    color: #6c8778;
    text-transform: none;
    font-size: 9px;
    letter-spacing: 0.9px;
    line-height: 1;
    margin-bottom: 4px;
    display: block;
    span{
        @extend .font--light;
        margin-left: 5px;
    }
    @include media-breakpoint-up(lg) {
        font-size: 10px;
        letter-spacing: 1px;
        margin-bottom: 5px;
    }
}

legend.label--formfield.nomargin{
    margin: 0;
}

label.label--form-check{
    font-size: 13px;
    color: #3c3c3c;
    position: relative;
    padding-left: 28px;
    margin: 0;
}

.form-control.form-field,
.input-group-text {
    @extend .font--light;
    font-size: 15px;
    color: $color-green-darker;
    border-radius: 2px;
    border: 1px solid $color-grey-bg;
    padding: 0 15px;
    &:focus, &:active{
        border: solid 1px $color-green-bright;
        outline: 0;
        box-shadow: none;
    }
    @include media-breakpoint-up(lg) {
        padding: 0 20px;
        font-size: 17px;
    }
}

textarea.form-control.form-field{
    padding: 15px;
    @include media-breakpoint-up(lg) {
        padding: 20px;
    }
}

.form-control.form-field.form-field--with-icon{
    padding-right: 35px;
}

.form-field::placeholder{
    @extend .font--light;
    font-size: 15px;
    color: $color-green-flat;
    @include media-breakpoint-up(lg) {
        font-size: 17px;
    }
}

textarea.form-field::placeholder{
    font-size: 13px;
    @include media-breakpoint-up(lg) {
        font-size: 15px;
    }
}

input.form-field {
    height: 50px;
    @include media-breakpoint-up(lg) {
        height: 60px;
    }
}

.form-check {
    padding-left: 9px;
    border: solid 1px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    height: 34px;
    input[type="checkbox"] {
        visibility: hidden;
    }
    input[type="checkbox"] + label:before {
        content: "\00a0";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        vertical-align: top;
        // margin-left: -37px;
        // margin-top: 2px;
        margin: 0;
        width: 18px;
        height: 18px;
        border-radius: 2px;
        background-color: $color-white;
        border: solid 1px #e8eceb;
    }
    input[type="checkbox"]:checked + label:before {
        background: $color-1;
        color: $color-white;
        line-height: 1.2;
        content: url(../../img/checkie.svg);
        text-align: center;
    }
    input[type="checkbox"]:checked + label:after {
        font-weight: bold;
    }
    input[type="checkbox"]:focus + label::before {
        outline: 0;
    }
}

.form-multiple-check{
    border: solid 1px rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    .form-check {
        padding-left: 15px;
        padding-right: 10px;
        border: 0;
        height: 48px;
        position: relative;
        &:after{
            content: "";
            position: absolute;
            bottom: 0;
            left: 15px;
            display: block;
            width: calc(100% - 30px);
            height: 1px;
            background-color: rgba(0, 0, 0, 0.05);
        }
        &:last-child:after{
            display: none;
        }
    }

}

.form-checkwrapper.show,
.form-checkbuttonwrapper.show,
.form-checkwrapper--default{
    // padding-bottom: 20px;
}

.form-check--filter {
    padding: 15px 0;
    border: 0;
    border-bottom: 1px solid #f1f4f3;
    height: auto;
    label{
        color: $color-green-darker;
    }
}

.form-check-send {
    padding: 0 20px;
    border: solid 1px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    height: 66px;
    input[type="checkbox"] {
        visibility: hidden;
    }

    input[type="checkbox"] + label:before {
        content: "\00a0";
        position: absolute;
        right: 0;
        margin: 0;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background-color: $color-white;
        border: solid 1px #e4e7e6;
    }
    input[type="checkbox"]:checked + label:before {
        background: $color-1;
        color: $color-white;
        content: url(../../img/checkie.svg);
        display: flex;
        align-items: center;
        justify-content: center;
        border: solid 1px $color-1;
    }
    input[type="checkbox"]:checked + label:after {
        font-weight: bold;
    }
    input[type="checkbox"]:focus + label::before {
        outline: 0;
    }
    label.label--form-check{
        display: flex;
        align-items: center;
        padding-left: 0;
        width: 100%;
        .icon-package{
            height: 26px;
            width: 30px;
            margin-right: 15px;
        }
    }
}

.form-check-input {
    margin-top: 0;
    margin-left: 0;
}

.input-helptext{
    @extend .text-centered;
    display: block;
    opacity: 0.5;
    font-size: 11px;
    margin-top: 8px;
}

.form-radio,
.form-checkbutton{
    margin-top: 10px;
    margin-bottom: 0;
    label{
        margin: 0;
    }
    p{
        @extend .font--azo;
        @extend .font--medium;
        padding: 8px 13px;
        font-size: 13px;
        color: white;
        background: $color-green-flat;
        border-radius: 2px;
        margin: 0 10px 0 0;
    }
    input[type="radio"]:checked + p,
    input[type="checkbox"]:checked + p{
        background: $color-1;
    }
    input[type="radio"],
    input[type="checkbox"]{
        display: none;
    }
}

.form-checkbuttonwrapper{
    display: flex;
    flex-wrap: wrap;
}

// bootstrap override

.invalid-feedback {
    display: block;
    color: #ca4a4a;
}

.form-section__footer{
    margin-top: 30px;
}

.invalid-feedback:first-letter {
text-transform: capitalize
}
