.profile--single-inner{
    .user__img-wrapper{
        background-color: $color-white;
        svg{
            fill: $color-grey-bg;
        }
    }
    @include media-breakpoint-up(sm) {
        border-radius: 2px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
        border: solid 1px #f1f4f3;
    }
}

.profile__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 1px;
    background-color: $color-grey-bg;
    padding-top: 40px;
    padding-bottom: 30px;
    @include media-breakpoint-up(sm) {
        padding-bottom: 40px;
    }
}

.profile__user-name{
    @extend .font--medium;
    color: $color-green-darker;
    font-size: 16px;
    margin: 0;
    margin-top: 20px;
    text-align: center;
    span{
        @extend .font--reg;
        color: $color-green-bright;
        font-size: 11px;
        display: block;
        margin-top: 5px;
    }
}

.profile__user-bio {
    padding: 10px 20px;
    text-align: center;
    font-size: 13px;
    color: #5a5a5a;
}

.bio__break {
    width: 30px;
    margin-bottom: 0;
}

.atsni-profile__link {
    color: $color-green-bright;
    text-decoration: none;
    &:hover,
    &:active,
    &:visited {
        color: $color-green-bright;
        text-decoration: none;
    }
}

.profile__menu{

    @include media-breakpoint-down(sm) {
        position: relative;
        &:after {
            content: "";
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            bottom: 15px;
            pointer-events: none;
            background-image: linear-gradient(to right, rgba(255,255,255,0), #fff 100%);
            width: 10%;
        }
    }
}

.profile__menu-ul{
    @extend .reset-list;
    padding: 0 10px;
    border: solid 1px $color-grey-bg;
    text-align: center;
    overflow-x: scroll;
    white-space: nowrap;
    @include media-breakpoint-up(sm) {
        padding: 10px 25px;
        border: 0;
        text-align: left;
        overflow: unset;
        white-space: unset;
    }
}

.profile__menu-li{
    @extend .font--medium;
    display: inline-block;
    // width: 100%;
	a{
        font-size: 13px;
		color: $color-green-darker;
        display: block;
        padding: 20px 12px;
        span{
            color: #c5ddd5;
            margin-left: 2px;
            display: inline-block;
        }
		&:hover, &:focus, &:active{
			color: $color-1;
			text-decoration: none;
			outline: 0;
		}
    }
    &.active{
        a{
            color: $color-1;
        }
    }
    @include media-breakpoint-up(sm) {
        display: block;
        width: 100%;
        a{
            display: block;
            padding: 15px 0;
            border-bottom: 1px solid $color-grey-bg;
            span{
                margin-left: 5px;
            }
        }
        &:last-child{
            a{
                border-bottom: 0;
            }
        }
    }
}

.profile__add-stek-wrapper{
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
}

.user__img-wrapper{
    border-radius: 50%;
    background: $color-grey-bg;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    img{
        height: 100%;
        width: auto;
        border-radius: 50%;
    }
    svg{
        fill: $color-white;
        width: auto;
        height: 75%;
    }
}

.user__img-wrapper--lg{
    width: 130px;
    height: 130px;
    @include media-breakpoint-up(sm) {
        width: 150px;
        height: 150px;
    }
}

.user__img-wrapper--md{
    width: 38px;
    height: 38px;
    @include media-breakpoint-up(sm) {
        width: 43px;
        height: 43px;
    }
}

.user__img-wrapper--sm{
    width: 33px;
    height: 33px;
}

.user__img-wrapper--xs{
    width: 19px;
    height: 19px;
}
