.place-input-wrapper {
	position: relative;
	height: 50px;
	width: 100%;
}

.form-group--location .place-input-wrapper {
	padding: 0;
	&:focus-within {
		border: solid 1px #7bbf9a;
	}
}

.place-input-wrapper:focus-within #label {
	display: none;
}

.place-input-wrapper input[type=text].location-input, //#location
.place-input-wrapper #label {
	@extend .font--azo;
	@extend .font--light;
	color: $color-green-darker;
	width: 100%;
	height: 50px;

	position: absolute;

	font-size: 15px;
	border: 1px solid rgb(241,244,243);
	border-radius: 2px;
	padding: 0 0 0 15px;
	@include media-breakpoint-up(lg) {
		font-size: 16px;
		border: 0;
		border-radius: 0;
	}
}

.form-group--location {
	.place-input-wrapper input[type=text].location-input, //#location
	.place-input-wrapper #label {
		@include media-breakpoint-up(lg) {
			padding: 0 0 0 20px;
		}
	}
}

.place-input-wrapper #label {
	margin: 0;
	line-height: 50px; //vuedoc
}

input[type=text].location-input { // #location
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-color: transparent;
	opacity: 0;

	&:focus{
		background-color: transparent;
		outline: 0;
		box-shadow: 0 2px 7px 0 #d2d2d226;

		opacity: 1;
	}
	@include media-breakpoint-up(lg) {
		&:focus{
			box-shadow: none;
		}
	}
}

.c-list-suggestions {
	@extend .reset-list;
	background-color: #fff;
	border: 1px solid $color-grey-bg;
	border-top: 0;
	border-radius: 2px;
	position: absolute;
	font-size: 15px;
	z-index: 100;

	line-height: 49px;

	width: 100%;
	left: 0;
	top: 50px;

	@include media-breakpoint-up(lg) {
		line-height: 55px;
	}
}

.searchbar__location-input .c-list-suggestions {
	top: 65px;
	left: -72px;
	width: 480px;
}

.c-suggestion,
.c-suggestion-helptext,
.c-suggestion-loading,
.c-suggestion-no-results {
	padding-left: 15px;
	@include media-breakpoint-up(lg) {
		//.form-group--location > &{
		//	padding-left: 50px;
		//}
		//.searchbar__location-input > &{
		//	padding-left: 105px;
		//}
	}
}

.form-group--location{
	.c-suggestion,
	.c-suggestion-helptext,
	.c-suggestion-loading,
	.c-suggestion-no-results {
		padding-left: 15px;
		@include media-breakpoint-up(lg) {
			padding-left: 20px;
		}
	}
}

.searchbar__location-input{
	.c-suggestion,
	.c-suggestion-helptext,
	.c-suggestion-loading,
	.c-suggestion-no-results {
		padding-left: 15px;
		@include media-breakpoint-up(lg) {
			padding-left: 25px;
		}
	}
}

.c-suggestion-helptext {
	@extend .font--scheme;
	color: $color-green-dark;
	font-size: 13px;
	line-height: 40px;
	@include media-breakpoint-up(lg) {
		font-size: 15px;
		line-height: 45px;
	}
}

.c-suggestion-no-results {
	color: $color-green-darker;
	font-weight: 300;
}

.c-suggestion-loading{
	@extend .font--light;
	color: $color-green-flat;
}

.c-suggestion{
	@extend .font--light;
	color: $color-green-darker;

	&:hover {
		cursor: pointer;
		background-color: #e8eceb;
	}
}

.c-suggestion-label-country {
	color: $color-green-flat;
}

.place-input-wrapper.show-map {
	height: 460px;
}

.place-input-wrapper.show-map .vue-map-container {
	margin-top: 50px;
}