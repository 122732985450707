.textpage-content {
    margin: 20px auto 0 auto;
    padding: 0 25px;

    p{
        font-size: 14px;
        line-height: 1.46;
        margin-bottom: 25px;
        color: $color-grey-text;
    }
    h2{
        margin-bottom: 15px;
    }
    h2{
        font-size: 18px;
        margin-bottom: 10px;
        color: $color-1;
    }
    h3{
        @extend .font--scheme;
        font-size: 15px;
        margin-bottom: 10px;
    }
    a:not(.button){
        color: $color-1;
        text-decoration: underline;
        &:hover,&:active,&:focus {
            color: $color-1;
            text-decoration: none;
        }
    }
    @include media-breakpoint-up(sm) {
        h2{
            font-size: 20px;
            margin-bottom: 15px;
        }
    }
    @include media-breakpoint-up(md) {
        margin: 50px auto 0 auto;
        p{
            font-size: 15px;
            line-height: 1.6;
        }
        h2{
            margin-bottom: 25px;
        }
        h3{
            font-size: 16px;
            margin-bottom: 15px;
        }
    }
    @include media-breakpoint-up(lg) {
        margin: 80px auto 0 auto;
    }
}

.textpage-content--post{
    padding: 0;
    margin-top: 15px;
    p{
        color: $color-grey-text-dark;
    }
    @include media-breakpoint-up(sm) {
        margin-top: 30px;
    }
}


.textpage-no-content-margin{
    margin-top: 20px;
    margin-bottom: 20px;
}