body {
    @extend .font--azo;
    @extend .font--reg;
    background: $color-white;
    color: $color-text-dark;
}

html, body {
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
}

.scrollock{
    height: 100vh;
    overflow: hidden;
}

main {
    flex: 1 0 auto;
}

.footer {
    flex-shrink: 0;
}

button{
    cursor: pointer;
}

.section {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
}

.section__row {
    margin-left: -$padding-xs/2;
    margin-right: -$padding-xs/2;
    @include media-breakpoint-up(sm) {
        margin-left: -$padding-sm/2;
        margin-right: -$padding-sm/2;
    }
    @include media-breakpoint-up(xl) {
        margin-left: -$padding-xl/2;
        margin-right: -$padding-xl/2;
    }
}

.section--xl{
    @include media-breakpoint-up(xl) {
        max-width: 1200px+($padding-xl*2);
    }
}

.section--lg{
    @include media-breakpoint-up(xl) {
        max-width: 996px+($padding-xl*2);
    }
}

.section--md{
    @include media-breakpoint-up(lg) {
        width: 892px;
    }
}

.section--sm{
    @include media-breakpoint-up(sm) {
        width: 695px;
    }
}

.section--xs{
    @include media-breakpoint-up(sm) {
        width: 491px;
    }
}

.section-flex{
    @include media-breakpoint-up(sm) {
        display: flex;
        justify-content: space-between;
    }
}

.section-half-column1{
    width: calc(100% + 40px);
    @include media-breakpoint-up(sm) {
        width: 48%;
    }
}

.section-half-column2{
    @include media-breakpoint-up(sm) {
        width: 49%;
    }
}

.section--padding{
    padding-left: $padding-xs;
    padding-right: $padding-xs;
    @include media-breakpoint-up(sm) {
        padding-left: $padding-sm;
        padding-right: $padding-sm;
    }
    @include media-breakpoint-up(xl) {
        padding-left: $padding-xl;
        padding-right: $padding-xl;
    }
}

.section--no-padding-mobile {
    @include media-breakpoint-down(xs) {
        padding-left: 0;
        padding-right: 0;
        .section__row {
            margin-left: 0;
            margin-right: 0;
        }
    }
}

.section--padding-mobile {
    @include media-breakpoint-down(xs) {
        padding-left: $padding-xs;
        padding-right: $padding-xs;
    }
}

.section--margin-top{
    margin-top: 20px;
    @include media-breakpoint-up(lg) {
        margin-top: 50px;
    }
}

.section--withsidebar {
    .cardcontainer {
        @include make-col(12);
        @include media-breakpoint-up(sm) {
            @include make-col(6);
            .stekcard {
                @include make-col(12);
            }
        }
        @include media-breakpoint-up(md) {
            @include make-col(8);
            .stekcard {
                @include make-col(6);
            }
        }
        @include media-breakpoint-up(lg) {
            @include make-col(9);
            .stekcard {
                @include make-col(4);
            }
        }
    }
}

.section__sidebar {
    @include make-col-ready();
    @include make-col(12);

    padding-left: 0;
    padding-right: 0;

    @include media-breakpoint-up(sm) {
        @include make-col(6);
        padding-left: $padding-sm/2;
        padding-right: $padding-sm/2;
    }
    @include media-breakpoint-up(md) {
        @include make-col(4);
        padding-left: $padding-sm/2;
        padding-right: $padding-sm/2;
    }
    @include media-breakpoint-up(lg) {
        @include make-col(3);
        padding-left: $padding-sm/2;
        padding-right: $padding-sm/2;
    }
    @include media-breakpoint-up(xl) {
        padding-left: $padding-xl/2;
        padding-right: $padding-xl/2;
    }
}


.section--withsidebar--index {
    .section__sidebar {
        @include media-breakpoint-down(md) {
            @include make-col(12);

            padding-left: 0;
            padding-right: 0;
        }
    }
    .cardcontainer {
        @include media-breakpoint-up(sm) {
            @include make-col(12);
            .stekcard {
                @include make-col(4);
            }
        }
        @include media-breakpoint-up(lg) {
            @include make-col(9);
            .stekcard {
                @include make-col(4);
            }
        }
    }
}

.section-header{
    margin-bottom: 20px;
    height: 35px;
    display: flex;
    align-items: flex-end;
}

.section-header--spread{
    justify-content: space-between;
}

.bg-white{
    background-color: $color-white;
}

.bg-green{
    background-color: $color-1;
}

.bg-grey{
    background-color: $color-grey-bg;
}

.hide-sm-down{
    @include media-breakpoint-down(sm) {
        display: none;
    }
}

.hide-sm-up{
    @include media-breakpoint-up(sm) {
        display: none;
    }
}

#page-overlay {
    background: #000;
    opacity: 0;
    width: 100%;
    height: 100%;
    position: fixed;
	top: 0;
	bottom: 0;
    left: 0;
	z-index: -1;
	transition-property: all;
	transition-duration: .1s;
    transition-timing-function: ease-in;
}

#page-overlay.page-overlay--show{
	opacity: 0.05;
	z-index: 9999;
}
